import axios from "axios"

const prefix = "flujo-dinero/flujo-general"

const flujoDinero = {
    informeFlujoGeneral: params => axios.get(`${prefix}/informe`, { params }),
    listarFlujoGeneral: params => axios.get(`${prefix}/listar`, { params }),
    filtroFlujoGeneral: params => axios.get(`${prefix}/filtro`, { params })
};

export default flujoDinero
