<template>
    <section>
        <div class="row mx-0 bg-white mt-2 py-3 br-12">
            <div class="col text-center border-right">
                <p class="text-general f-30 f-500"> {{ separadorNumero(informe.hoy.valor) }} </p>
                <div class="row mx-0 mt-1">
                    <p class="col text-center text-general f-14">Hoy</p>
                    <p v-if="informe.hoy.porcentaje != 0" :class="`text-general${informe.hoy.porcentaje < 0 ? '-red' : '' }`" class="col text-center f-14"> <i :class="`icon-${informe.hoy.porcentaje >= 0 ? 'up' : 'down'}`" /> {{ agregarSeparadoresNumero(informe.hoy.porcentaje,1) }}% </p>
                </div>
            </div>
            <div class="col text-center border-right">
                <p class="text-general f-30 f-500">{{ separadorNumero(informe.ayer.valor) }}</p>
                <div class="row mx-0 mt-1">
                    <p class="col text-general text-center f-14 mt-1">Ayer</p>
                    <p v-if="informe.ayer.porcentaje != 0" :class="`text-general${informe.ayer.porcentaje < 0 ? '-red' : '' }`" class="col text-center f-14"> <i :class="`icon-${informe.ayer.porcentaje >= 0 ? 'up' : 'down'}`" /> {{ agregarSeparadoresNumero(informe.ayer.porcentaje,1) }}% </p>
                </div>
            </div>
            <div class="col text-center border-right">
                <p class="text-general f-30 f-500">{{ separadorNumero(informe.semanal.valor) }}</p>
                <div class="row mx-0 mt-1">
                    <p class="col text-general text-center f-14 mt-1">Semana actual</p>
                    <p v-if="informe.semanal.porcentaje != 0" :class="`text-general${informe.semanal.porcentaje < 0 ? '-red' : '' }`" class="col text-text-center f-14"> <i :class="`icon-${informe.semanal.porcentaje >= 0 ? 'up' : 'down'}`" /> {{ agregarSeparadoresNumero(informe.semanal.porcentaje,1) }}% </p>
                </div>
            </div>
            <div class="col text-center">
                <p class="text-general f-30 f-500">{{ separadorNumero(informe.mes.valor) }}</p>
                <div class="row mx-0 mt-1">
                    <p class="col text-general text-center f-14 mt-1">Mes actual</p>
                    <p v-if="informe.mes.porcentaje != 0" :class="`text-general${informe.mes.porcentaje < 0 ? '-red' : '' }`" class="col text-center f-14"> <i :class="`icon-${informe.mes.porcentaje >= 0 ? 'up' : 'down'}`" /> {{ agregarSeparadoresNumero(informe.mes.porcentaje,1) }}% </p>
                </div>
            </div>
        </div>
        <!-- Filto -->
        <div class="row mx-0 border-bottom mt-3">
            <div
            v-for="(data, i) in meses" :key="i"
            class="col p-2 f-14" :class="data.activo ? (mes == data.fecha ? 'cr-pointer border-b-black f-600 text-general' :'cr-pointer text-general2') :'text-gris' "
            @click="seleccionarMes(data)"
            >
                {{ data.mes | ucfirst }}
            </div>
        </div>
        <!-- Filto -->
        <div class="row mx-0 mt-3">
            <div class="col-12 px-0 bg-white br-t-12">
                <tabla-general
                :data="dataTable"
                class-header="text-general f-16"
                alto="calc(100vh - 402px)"
                :usar-paginacion="false"
                :mostrar-buscador="false"
                >
                    <template slot="cabecera-izquierda">
                        <div class="col-auto pl-5 text-general f-18 f-600">
                            Flujo general de dinero
                        </div>
                        <div class="col d-flex">
                            <!-- <div class="col-auto text-general f-18 f-600">
                                Ingresos a la caja
                            </div> -->
                            <div class="col text-right text-general f-18">
                                <span class="text-general"> Total </span>
                                {{ separadorNumero(total) }}
                            </div>
                            <div v-if="total > 0" class="bg-light-f5 ml-auto border cr-pointer d-middle px-2 br-20 text-general position-relative" @click="filtrar">
                                <div v-if="filtro" class="rounded-circle bg-general position-absolute" style="width:10px;height:10px;right:-2px;top:-5px;" />
                                <i class="icon-filter-outline f-16" /> Filtrar
                            </div>
                        </div>
                    </template>
                    <template slot="adicionales-izquierda">
                        <el-table-column
                        label="Fecha"
                        width="180"
                        sort-by="fecha"
                        sortable
                        >
                            <template slot-scope="{ row }">
                                <p>{{ row.dia }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="Pagos en caja" width="250" class="text-general text-right" sort-by="caja" sortable>
                            <template slot-scope="{ row }">
                                {{ separadorNumero(row.caja) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Pagos por transferencia" width="250" class="text-general text-right" sort-by="cuenta" sortable>
                            <template slot-scope="{ row }">
                                {{ separadorNumero(row.cuenta) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Pagos por pasarela" width="250" class="text-general text-right" sort-by="pasarela" sortable>
                            <template slot-scope="{ row }">
                                {{ separadorNumero(row.pasarela) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Pagos por comisión" width="250" class="text-general text-right" sort-by="comision" sortable>
                            <template slot-scope="{ row }">
                                {{ separadorNumero(row.comision) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Total" class="text-general text-right" sort-by="total" sortable>
                            <template slot-scope="{ row }">
                                {{ separadorNumero(row.total) }}
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
        </div>
        <!-- Partials -->
        <modal-filtro-general ref="modalFiltro" @filtrar="listarFlujoGeneral" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import FlujoGeneral from "~/services/flujoDinero/flujo_general";
import moment from 'moment'
import ucfirst from '~/filters/Ucfirst';
export default {
    components: {
        modalFiltroGeneral : () => import('./partials/modalFiltroGeneral')
    },
    data(){
        return {
            dataTable: [],
            informe: {
                hoy: {
                    valor: 0,
                    porcentaje: 0,
                },
                ayer: {
                    valor: 0,
                    porcentaje: 0,
                },
                semanal: {
                    valor: 0,
                    porcentaje: 0,
                },
                mes: {
                    valor: 0,
                    porcentaje: 0,
                },
            },
            mes: moment().startOf('month').format('YYYY-MM-DD'),
            total: 0,
            filtro: 0
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
        meses(){
            let hoy = moment()
            let anio = moment().subtract(11, 'months')
            let data = []
            while(anio.isBefore(hoy)){
                let fecha = anio.startOf('month').clone()
                let model = {
                    fecha: fecha.format('YYYY-MM-DD'),
                    mes:  fecha.format('MMM YYYY'),
                    activo: true
                }
                data.push(model)
                anio.add(1, 'month')
            }

            return data
        }
    },
    watch: {
        id_cedis(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        },
        id_moneda(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        }
    },
    mounted(){
        this.montar()
    },
    methods: {
        async montar(){
            try {
                if(this.id_cedis === null || this.id_moneda == null){
                    setTimeout(async() => {
                        await this.informeFlujoGeneral()
                        await this.listarFlujoGeneral()
                    }, 1000);
                }else{
                    await this.informeFlujoGeneral()
                    await this.listarFlujoGeneral()
                }
            } catch (error){
                this.error_catch(error)
            }finally{
            }
        },
        filtrar(){
            this.$refs.modalFiltro.toggle({ filtro:this.filtro, fecha: this.mes});
        },
        async listarFlujoGeneral(params = {}){
            try {
                this.filtro = params.filtro
                params.fecha = this.mes
                params.id_moneda = this.id_moneda
                const { data } = await FlujoGeneral.listarFlujoGeneral(params)
                this.dataTable = data.data.flujoGeneral
                this.total = data.data.total
            } catch (error){
                this.error_catch(error)
            }
        },
        async informeFlujoGeneral(params = {}){
            try {
                params.id_moneda = this.id_moneda
                const { data } = await FlujoGeneral.informeFlujoGeneral(params)
                this.informe = data.data.informe
            } catch (error){
                this.error_catch(error)
            }
        },
        seleccionarMes(data){
            data.activo ? this.mes = data.fecha : ''
            this.listarFlujoGeneral()
        }
    }
}
</script>
<style lang="scss" scoped>
.border-b-black{
    border-bottom: 2px solid var(--text-general);
}
</style>
